<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" :offset="customRangeOffset" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                height="420px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import ApexCharts from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const startDateOffset = -4
const yAxisMax = 3000

export default {
    name: 'AnimalActivityChartView',
    components: {
        ApexCharts,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            series: [
                {
                    name: this.$t('activity'),
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    stacked: false,
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: false,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                },
                annotations: {
                    xaxis: [],
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    title: {
                        text: this.$i18n.t('activity'),
                    },
                    min: 0,
                    max: yAxisMax,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .add(startDateOffset, 'hours')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            customRangeOffset: [startDateOffset, null],
            isLoading: false,
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        assets() {
            const id = parseInt(this.id)

            if (!id) {
                return null
            }

            switch (this.$route.name) {
                case 'animalactivitychart':
                    return this.trackers.filter(item => item.id === id)
                case 'locationAnimalActivityChart':
                    return this.trackers.filter(item => item.location === id)
                default:
                    return null
            }
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        async load() {
            this.isLoading = true
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            let url =
                '/timeseries/activity/?' +
                'agg=mean' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}`

            if (this.assets) {
                this.assets.forEach(asset => {
                    url += '&asset=' + asset.asset
                })
            }

            const { data } = await httpHelper.get(url)
            this.series[0].data = data.map(item => [
                item.time,
                Math.round(item.mean),
            ])
            this.chartOptions.yaxis.max =
                Math.max(...data.map(item => item.mean)) > yAxisMax
                    ? undefined
                    : yAxisMax
        },
    },
}
</script>

<i18n>
{
    "en": {
        "activity": "Activity"
    },
    "de": {
        "activity": "Aktivität"
    },
    "fr": {
        "activity": "Activité"
    },
    "it": {
        "activity": "Attività"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
